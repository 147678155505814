import React, { Component } from "react";
import Slider from "react-slick";

export default class TrustPilot extends Component {
  render() {
    const settings = {
      dots: false,
      fade: true,
      arrows: false,
      pauseOnHover: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 300,
      autoplaySpeed: 5000,
      cssEase: "linear",
    };
    return (
      <div>
        <Slider {...settings}>
          {/* <div> */}
          {/* <div className="trustpilotreview"> */}
          {/* <div className="review"> */}
          {/* <div className="quote"> */}
          {/* <p>Swift by name and swift by nature...they are brilliant operators who work fast and efficiently and are polite and offer great advice. I have dealt with Ian Dawes who is always helpful. I highly recommend them!</p> */}
          {/* <h5>Chris Edgcumbe</h5> */}
          {/* </div> */}
          {/* <div className="mark"> */}
          {/* " */}
          {/* </div> */}
          {/* </div> */}
          {/* <div className="trusty"> */}
          {/* <img alt="trust pilot" src="../img/trust-review.svg" height="25px" /> */}
          {/* <img alt="trust pilot" src="../img/trust-rating.svg" height="15px" /> */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
          <div>
            <div className="trustpilotreview">
              <div className="review">
                <div className="quote">
                  <p>
                    The online system is also easy to use - more or less ! - but
                    which when combined with the amazing support makes the whole
                    currency exchange process so easy, I can easily and quickly
                    execute my transactions when the rate is in my favour -
                    therefore saving me money - perfect.{" "}
                  </p>
                  <h5>CF</h5>
                </div>
                <div className="mark">"</div>
              </div>
              <div className="trusty">
                {/* <img
                  alt="trust pilot"
                  src="../img/trust-review.svg"
                  height="25px"
                /> */}
                <img
                  alt="trust pilot"
                  src="../img/trust-rating.svg"
                  height="15px"
                />
              </div>
            </div>
          </div>
          {/* <div> */}
          {/* <div className="trustpilotreview"> */}
          {/* <div className="review"> */}
          {/* <div className="quote"> */}
          {/* <p>A wonderful service, great communicators, gives me such confidence. It’s the only FX option for me based on safety, best rates available and fastest turn around in the market. </p> */}
          {/* <h5>Toby Osborne</h5> */}
          {/* </div> */}
          {/* <div className="mark"> */}
          {/* " */}
          {/* </div> */}
          {/* </div> */}
          {/* <div className="trusty"> */}
          {/* <img alt="trust pilot" src="../img/trust-review.svg" height="25px" /> */}
          {/* <img alt="trust pilot" src="../img/trust-rating.svg" height="15px" /> */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
          <div>
            <div className="trustpilotreview">
              <div className="review">
                <div className="quote">
                  <p>
                    Very professional and easy business to deal with. The
                    platform is intuitive with any queries and questions quickly
                    explained by my account manager.{" "}
                  </p>
                  <h5>Austin</h5>
                </div>
                <div className="mark">"</div>
              </div>
              <div className="trusty">
                {/* <img
                  alt="trust pilot"
                  src="../img/trust-review.svg"
                  height="25px"
                /> */}
                <img
                  alt="trust pilot"
                  src="../img/trust-rating.svg"
                  height="15px"
                />
              </div>
            </div>
          </div>
        </Slider>

        <div className="">
          <h4>
            *All testimonials, reviews, opinions or case studies presented on
            our website may not be indicative of all customers. Results may vary
            and customers agree to proceed at their own risk.
          </h4>
        </div>
      </div>
    );
  }
}
